.events a {
  text-decoration: none;
  color: var(--lightBlueColor);
}

.events a:hover {
  background-color: var(--headerColors);
  padding: 12px;
  color: var(--textHeadline);
}
