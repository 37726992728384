
.news a {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  color: var(--lightBlueColor);
}
  
  table, th, td {
    padding-right: 5%;
    padding-bottom: 3%;
    font-weight: 500;       
    text-align: left;
    text-decoration: none;
    color: var(--lightBlueColor);
  }