/* Blogs Title Page */
.card {
  /* width: 24rem; */
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.blogs {
  margin-bottom: 1cm; 
}

.blogs .col {
  transition: transform 0.9s;
}

.blogs .card-title,
.blogs .card-text {
  color: var(--headerColors);
  /* text-align: justify; */
}

.blogs-first {
  background-color: rgb(230,230,230);
}


.card-img-top {
  height: 7cm;
}
.agtech {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
}
.agtech2 {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
}
.blogs a {
  text-decoration: none;
}

/* Full Blogs */

.full_blogs p,
.full_blogs h4,
.full_blogs h5,
.full_blogs li {
  color: var(--headerColors);
}
.full_blogs li {
  margin-left: 0.5cm;
}
.full_blogs {
  margin-bottom: 0.5cm;
}

.full_blogs a {
  /* text-decoration: none; */
  color: var(--headerColors);
}

/* Small screen */
@media screen and (max-width: 991px) {
  .blogs .col {
    margin-top: 0.5cm;
  }
}

/* Large screen */
@media screen and (min-width: 991px) {
  .blogs .col:hover {
    transform: scale(1.04, 1.04);
    transition-delay: 2ms;
  }
}

.ul-disc ul {
  list-style-type: disc;
}

