#publications h1,#publications p, #publicationsh6{
  color: var(--headerColors);
}

#publications-title{
  background: var(--lightBlueColor) ;
  color: var(--textHeadline);
}

#publications-title h3{
  padding-top: 0.3cm;
}

#publications-introduction,#publications-main-intro{
  color: var(--headerColors);
  margin-top: 0.0cm;
  margin-bottom: 0.5cm;
  text-align: justify;

}

#publications h6{
  font-weight: lighter;
}

#publications{
  margin-bottom: var(--sectionBottonSpace);
  text-align: justify;
}

#publications-table{
  margin-bottom: var(--sectionBottonSpace);


  
}

#publications-table,#publications-table th,#publications-table td{
  border: 1px black solid;
}

#publications-table a{
  color: var(--headerColors);
  text-decoration: none;
}
#publications-table th,#publications-table td{
  color: var(--headerColors);
  text-align: center;
  vertical-align: middle;
}

#pdf-icon {
  color:#C41E3A;
}

#video-icon {
  color:green;  
}


