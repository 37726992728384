/* Partner profiles  */
#Profiles .row{
    text-align: justify;

}
#partner-intro{
    margin-top: 1cm;
    margin-bottom: 1cm;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

#partnerInroduction{
    color: var(--headerColors);
    margin-right: 0.3cm;
    margin-left: 0.3cm;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    text-align: justify;  

}

#projectRole{
    color: var(--headerColors);;
    margin-bottom: 0.5cm;
    margin-left: 0.3cm;

}

#projectRole,#projectRelatedExpertise,#teamMembers{
    padding-left: 0;
    color: var(--headerColors);
}
#projectRoleDetails{
    background: var(--lightBlueColor) ;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-bottom: 1cm;

}
#projectRoleIntroduction{
    color:var(--textHeadline);
    margin-left: 0.3cm;
    margin-right: 0.3cm;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;

}
#projectRelatedExpertise{
    
    margin-bottom: 0.5cm;
    margin-left: 0.3cm;

}
#projectRelatedExpertiseDetails{
    background: var( --textHeadline) ;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-bottom: 1cm;

}

#projectRelatedExpertiseIntroduction{
    color:var( --headerColors) ;
    margin-left: 0.3cm;
    margin-right: 0.3cm;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;

}

#teamMembers{
    color: var( --headerColors) ;
    margin-bottom: 0.5cm;
    margin-left: 0.3cm;

}

#teamMemberDetails{
    margin-bottom: 1cm;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 0.3cm;

}

#teamMemberName{
    color:var(--headerColors) ;
    margin-bottom: 0.5cm;
    margin-top: 0.5cm;
    margin-left: 0.3cm;

}
#memberIntroduction,#projectRelatedExpertiseIntroduction{
    color:  var( --headerColors) ;
    margin-left: 0.3cm;
    margin-right: 0.3cm;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    text-align: justify;

}
