#press h1,#press p, #press h6{
    color: var(--headerColors);
  }
  #press h6{
    font-weight: lighter;
  }

  #press{
    margin-bottom: var(--sectionBottonSpace);
    text-align: justify;
  }

.languages{
  color:var(--lightBlueColor);
}
.languages button{
  background: none;
  border: 0;
  
  
}

.languages a{
  text-decoration: none;
  color:var(--lightBlueColor);
   
}

