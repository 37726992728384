
:root{

  --darkblueColor:#3A3ADD;
  --lightBlueColor:#57A9DC;
  --greenColor:#008700;
  --pargraphSpace :0.5cm;
  --headerColors :#247CB2;
  --textHeadline:#fff;
  --sectionBottonSpace:1cm;
  box-sizing: border-box;
  

}


/* body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  
 
  min-height: 100vh;

  flex-direction: column;
  display: flex;
} */


body{
  margin: 0;
  
 
}