.home h1{
    font-weight:140;
    
}
.home h2{
    
    font-size: 18px;
}
.home p{
    font-weight: 20px;
    font-size: 18px;
}
.row1 ,.row2, .row3,.row4 {
    border-bottom-left-radius: 0.2cm;
    border-bottom-left-radius: 0.2cm;

}
.row1{
    background-color: var(--lightBlueColor);
    box-shadow: 0 4px 8px 0 #D7E4F1;
    transition: 0.3s;
    
    margin-bottom: var(--sectionBottonSpace);
}
.row1 h1{
    text-align: center;
    margin-top: var(--pargraphSpace );
    margin-bottom: var(--pargraphSpace);
    color: var(--textHeadline);
   
    
}
.row1 p{
    margin-bottom: var(--pargraphSpace);
    text-align: center;
    color: var(--textHeadline);
}

.row2{

    box-shadow: 0 4px 8px 0 #D7E4F1;
     transition: 0.3s;
    margin-bottom: 1cm;
    position: relative;
    
    margin-bottom: var(--sectionBottonSpace);

}

.row2 img{
    padding: 0;
    margin-bottom: var(--pargraphSpace);
}

.row2 h1,.row4 h1{
    text-align: center;
    margin-top: var(--pargraphSpace );
    margin-bottom: var(--pargraphSpace);
    color: var(--headerColors);
}

.row2 p{
    margin-bottom: var(--pargraphSpace);
    text-align: center;
    color: var(--headerColors);
}

.row3 p{
    margin-bottom:var(--pargraphSpace) ;
    color: var(--textHeadline);

    
}

.row3{
    background-color: var(--lightBlueColor);
    box-shadow: 0 4px 8px 0 #D7E4F1;
    transition: 0.3s;
    
    margin-bottom: var(--sectionBottonSpace);
}

.row3 h1{
    text-align: center;
    margin-top: var(--pargraphSpace );
    margin-bottom: var(--pargraphSpace);
    color: var(--textHeadline);
}



.row4{
    box-shadow: 0 4px 8px 0 #D7E4F1;
    transition: 0.3s;
   margin-bottom: var(--sectionBottonSpace);
}

.row4 p{    
    color: var(--headerColors);    
    margin-bottom: var(--pargraphSpace);


}


.row5 p{    
    color: var(--textHeadline); 
}

.row5{
    background-color:  #D7E4F1;
    box-shadow: 0 4px 8px 0 #D7E4F1;
    transition: 0.3s;    
    
    margin-bottom: var(--pargraphSpace);
}

.row5 h2{    
    text-align: left;    
    color: var(--headerColors);
    margin-top: 0.2cm;
    margin-bottom: 0.2cm;
}

.row5 {
    border-top-left-radius: 0.2cm;
    border-bottom-left-radius: 0.2cm;
}

.sur p{
    text-align: center;
}

#objectives,#vision,#challenges{
    font-weight: bold;
}

