#consortium-intro{
    color: var(--headerColors);
    padding-left:0;
}
#profile-header{
    padding-left: 0;
    color: var(--headerColors);
    margin-bottom: 0.5cm;
}
#consortium-image{
    
    margin-bottom: var( --sectionBottonSpace);
}
#consortium-table{
    margin-bottom: var(--sectionBottonSpace);
 

    
}

#consortium-table,#consortium-table th,#consortium-table td{
    border: 1px black solid;
}

#consortium-table a{
    color: var(--headerColors);
    text-decoration: none;
}
#consortium-table th,#consortium-table td{
    color: var(--headerColors);
    
}

@media only screen and (min-width: 768px) {

    #consortium-image{
       
        width: 50%;

        margin-left: 25%;
        margin-right: 25%;
    

    }


} 

@media only screen and (max-width: 600px) {
    #consortium-table{
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    #profile-header{
        padding-left: 2.5%;
        
        
    }


} 

