
.App{
   display: flex;
   position: relative;
  flex-direction: column;
  height: 100vh;
  
  
}

main{
  flex: 1;
  
}

#main-title{
  background-color: var(--lightBlueColor);
  margin-bottom: var(--sectionBottonSpace)
  
}
#main-title h1{
  color: var(--textHeadline);
  
  margin-bottom: var(--sectionBottonSpace);




  padding-top: 0.5cm;
 

}

#careup{
  font-weight:bold;
}
/* blue #3A3ADD

Blue1: #57A9DC
 Green : #008700 */