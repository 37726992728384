/* footer{
    background-color:var(--lightBlueColor);
    border-top-right-radius: 0.2cm;
    border-top-left-radius: 0.2cm;
    position:absolute;
    display: block !important;
    bottom: 0;
    margin-top: 25px;
    width: 100%;
        
} */

footer {
  background-color: var(--lightBlueColor);
  border-top-right-radius: 0.2cm;
  border-top-left-radius: 0.2cm;
  flex-shrink: 0;
}

.content {
  margin-top: var(--pargraphSpace);
  margin-bottom: var(--pargraphSpace);
  color: #ffff;
}

#aal-p {
  text-justify: distribute-all-lines;
  margin-top: var(--pargraphSpace);
}
#aal-p img {
  display: inline-flex;
}
#agencies {
  height: 60%;

  width: 60%;
  background: white !important;
  border: none !important;
  border-radius: 0.1cm;
  float: left;
  margin-right: 0.5cm;
}
@media only screen and (max-width: 600px) {
  #agencies {
    height: fit-content;
    width: 100%;
    border-radius: 0.5cm;
  }
}

#copy {
  color: var(--textHeadline);
}
