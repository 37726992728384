#leaflets h1,#leaflets p, #leaflets h6{
    color: var(--headerColors);
}

#leaflets-title{
    background: var(--lightBlueColor) ;
    color: var(--textHeadline);
}

#leaflets-introduction,#leaflets-main-intro{
  color: var(--headerColors);
  margin-top: 0.0cm;
  margin-bottom: 0.5cm;
  text-align: justify;

}

#leafletse-title h3{
    padding-top: 0.3cm;
}

#leaflets h6{
  font-weight: lighter;
}

#leaflets{
  margin-bottom: var(--sectionBottonSpace);
  text-align: justify;
}

#leaflets-table{
  margin-bottom: var(--sectionBottonSpace);


  
}

#leaflets-table,#leaflets-table th,#leaflets-table td{
  border: 0;
  width: 55%;
}

#leaflets-table a{
  color: var(--headerColors);
  text-decoration: none;
}
#leaflets-table th,#leaflets-table td{
  color: var(--headerColors); 
}

#leafleft-li li{
  margin-left: 20%;
}



