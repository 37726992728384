
#workpackage-title{
    background: var(--lightBlueColor) ;
    color: var(--textHeadline);

}
#workpackage-title h3{
    padding-top: 0.3cm;
}
#workpackage-introduction,#workpackage-main-intro{
    color: var(--headerColors);
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    text-align: justify;

}

ul {
    list-style-type: symbols(cyclic "\22C4");
}