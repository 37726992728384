.menu_bar {
  background-color: var(--lightBlueColor);
}

.menu_bar img {
  height: 0.79cm;
  width: 4.83cm;
  margin-left: 10px;
}
.nav-link {
  color: var(--headerColors);
}

.under_menu {
  padding-top: 2cm;
}

nav {
  background-color: var(--textHeadline);
  border-top-right-radius: 0.5cm;
  border-top-left-radius: 0.5cm;
}

header {
  position: sticky;
}

.dropdown-item.active {
  background-color: var(--lightBlueColor);
}
.dropdown-item:active {
  background-color: transparent !important;
  color: black;
}
/* Large screen */
@media screen and (min-width: 991px) {
  header {
    padding-top: 0.5cm;
    /* padding-bottom: 0.5cm; */
  }

  nav {
    padding-top: 0.5cm !important;
    padding-bottom: 0.5cm !important;
  }
  .nav-link {
    /* margin-left: 30px; */
    font-size: 20px;

    padding: 15px;
  }

  #navbarNavDropdown {
    margin-left: 15%;
  }

  .nav-item {
    margin-left: 30px;
  }

  .dropdown-item:hover {
    background-color: none;
  }

  .nav-item:hover {
    background-color: var(--headerColors);

    color: var(--textHeadline) !important;
    border-radius: 0.3cm;
  }

  .nav-link:hover {
    color: var(--textHeadline);
  }

  .navbar-nav .nav-link.active {
    /* background-color: var(--headerColors); */
    color: var(--textHeadline) !important;
    text-decoration: none !important;
    border-radius: 0.3cm;
  }
}

/* Small devices */
@media screen and (max-width: 991px) {
}
